import { withTrackedView } from "helpers/analyticsHelpers";
import { route, compose, mount, redirect } from "navi";
import { View } from "react-navi";
import Layout from "./Layout";
import AccountCreationPage from "components/AccountCreation/AccountCreationPage";
import { LoginPage } from "components/Login/LoginPage";
import { ForgotPasswordPage } from "components/ForgotPassword/ForgotPassword";

export const routes = compose(
  mount({
    "/create-account": withTrackedView(
      <Layout>
        <View />
      </Layout>,
      route((req) => {

        const {clientId, location} = req.params;

        const checkForRedirect = (clientId: string, location: string) => {
          if (!clientId) {
            if (location !== "app") {
              return true;
            }
          }

          if (clientId !== "Switcher" && clientId !== "ShopifyApp" ) {
            return true;
          }

          return false;
        }

        if (checkForRedirect(clientId, location)) {
          window.top.location.href = `${process.env.REACT_APP_DASHBOARD_HOST_URI}/getting-started`

          return {
            title: "",
            view: <></>,
          }
        } else { 
          return {
            title: "create-account",
            view: <AccountCreationPage props={req.params} />,
          };
        }
      })
    ),
    "/login": withTrackedView(
      <Layout>
        <View />
      </Layout>,
      route((req) => {
        return {
          title: "login",
          view: <LoginPage props={req.params} />,
        };
      })
    ),
    "/forgot-password": withTrackedView(
      <Layout>
        <View />
      </Layout>,
      route({
        title: "forgot-password",
        view: <ForgotPasswordPage />,
      })
    ),
    // backwards-compat routes
    "/": redirect((req) => {
      return `/create-account${req.search ? req.search + "&location=iframe" : "?location=iframe"}`
    }),
    "/iframe": redirect((req) => {
      return `/create-account${req.search ? req.search + "&location=iframe" : "?location=iframe"}`
    }),
    "/app": redirect((req) => {
      return `/create-account${req.search ? req.search + "&location=app" : "?location=app"}`
    }),
  })
);
