import React from "react";
import { LoginInput } from "components/text-input/LoginInput";
import { IUserLogin } from "store/user/types";
import { useForm, Errors } from "hooks/useForm";
import { useTranslation } from "react-i18next";
import styles from "./AccountCreationForm.module.scss";
import { Link, useCurrentRoute } from "react-navi";
import { useGetHostClient } from "hooks/useGetHostClient";
import { LogoAndPrompt } from "./shared/LogoAndPrompt";

interface LoginFormProps {
  /** Handler for login form submission. */
  onSubmit: (payload: IUserLogin) => Promise<void>;
}

/**
 * Login form.
 */
export const LoginForm: React.FC<LoginFormProps> = ({
  onSubmit,
}: LoginFormProps) => {
  const { t } = useTranslation();
  const { url } = useCurrentRoute();
  const { values, errors, isSubmitting, handleChange, handleSubmit } =
    useForm<IUserLogin>(
      {
        username: "",
        password: "",
      },
      async (values: IUserLogin): Promise<void> => await onSubmit(values),
      (values: IUserLogin) => {
        let errors: Errors = {};
        if (!values.username) {
          errors.username = t("errors:username-required-error");
        } else if (!/\S+@\S+\.\S+/.test(values.username)) {
          errors.username = t("errors:username-invalid-error");
        }

        if (!values.password) {
          errors.password = t("errors:password-required-error");
        }

        return errors;
      }
    );
    
    const { hostClient } = useGetHostClient();

  return (
    <div className={styles["content-wrapper"]}>
      <div className={styles["form-wrapper"]}>
        {hostClient.formIsStyled && (
          <LogoAndPrompt prompt="login:login-prompt" />
        )}
        <form onSubmit={handleSubmit} className={styles["login-form"]} id={styles["login-form"]}>
          <LoginInput
            label={t("create-account:email-address")}
            type="email"
            id="username"
            value={values.username}
            error={errors.username}
            onChange={handleChange}
          />
          <LoginInput
            label={t("create-account:create-password")}
            type="password"
            id="password"
            value={values.password}
            error={errors.password}
            onChange={handleChange}
          />
          {errors.api && (
            <div className="alert alert-danger" role="alert">
              {t(
                "errors:" +
                  (errors.apiJSON?.error
                    ? errors.apiJSON.error
                    : "incorrect-login")
              )}
            </div>
          )}
          {hostClient.formIsStyled && (
            <div className={`text-center ${styles["account-links"]}`}>
              {/* /forgot-password route not supported yet */}
              <Link href={`/forgot-password${url.search}`} className={styles["login-links"]}>
                {t("create-account:forgot-password")}
              </Link>
              {!hostClient.minimizeFormContent && (
                <p className={styles["create-account"]}>
                  <Link href={`/create-account${url.search}`} className={styles["login-links"]}>
                    {t("buttons:create-account")}
                  </Link>
                </p>
              )}
            </div>
          )}
          <button
            type="submit"
            className="btn btn-white md-btn btn-block mb-4"
            disabled={isSubmitting}
          >
            {t("buttons:login").toUpperCase()}
          </button>
        </form>
      </div>
      {!hostClient.formIsStyled && (
        <div className={`text-center ${styles["legal-text"]}`}>
          {/* /forgot-password route not supported yet */}
          <Link href={`/forgot-password${url.search}`} className={styles["login-links"]}>
            {t("create-account:forgot-password").toUpperCase()}
          </Link>
          <p className={styles["create-account"]}>
            {t("create-account:dont-have-account")}{" "}
            <Link href={`/create-account${url.search}`} className={styles["login-links"]}>
              {t("buttons:create-account").toUpperCase()}
            </Link>
          </p>
        </div>
      )}
      {hostClient.formIsStyled && (
        <div className={styles["legal-text"]}>
          <p className={styles["styled-create-account"]}>
            {t("create-account:dont-have-account")}{" "}
            <Link href={`/create-account${url.search}`} className={`text-center ${styles["login-links"]}`}>
              {t("buttons:create-account").toUpperCase()}
            </Link>
          </p>
          <p>
              {t("create-account:terms-and-privacy-1")}
              {" "}
              <Link
                className={`text-center ${styles["login-links"]}`}
                target="_blank"
                href="https://www.switcherstudio.com/terms-of-use"
              >
                {t("create-account:terms-and-privacy-2")}
              </Link>
              {" "}
              {t("create-account:terms-and-privacy-3")}
              {" "}
              <Link
                className={`text-center ${styles["login-links"]}`}
                target="_blank"
                href="https://www.switcherstudio.com/privacy-policy"
              >
                {t("create-account:terms-and-privacy-4")}
              </Link>
            </p>
        </div>
      )}
    </div>
  );
};
