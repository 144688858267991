import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { View } from "react-navi";
import { RootState } from "store/reducers";
import Loading from "components/loading/Loading";
import { NotificationContainer } from "components/notification/NotificationContainer";
import { removeNotification } from "store/notification/slice";
import { useGetHostClient } from "hooks/useGetHostClient";

const Layout: React.FC = () => {
  const { notifications } = useSelector((s: RootState) => s.notification);
  const { loading } = useSelector((state: RootState) => state.loading);
  const dispatch = useDispatch();
  const { hostClient } = useGetHostClient();

  return (
    <div className={hostClient.appWrapperClass}>
      <Loading isLoading={loading > 0} />
      <NotificationContainer 
        visible={notifications.length > 0}
        notifications={notifications}
        close={(id) => dispatch(removeNotification(id))}
        fixed={false}
      />
      <View />
    </div>
  );
}

export default Layout;
