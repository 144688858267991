import React from "react";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { ReactComponent as SuccessIcon } from "assets/icons/success.svg";
import styles from "./Banner.module.css";

export interface BannerProps {
  header: string;
  hide?: boolean;
  setHide?: (state: boolean) => void;
  subheader?: string;
  canClose?: boolean;
}

export const Banner: React.FC<BannerProps> = ({ header, subheader, hide, setHide, canClose }) => {

  return (
    !hide && (
      <div className={styles["status-banner-container"]}>
        <div className={styles["status-banner"]}>
          <SuccessIcon className={styles["icon"]} />
          <span className={styles["message"]}>
            {header} <br></br>
            <small>
              {subheader ? subheader : ""}
            </small>
          </span>
          { canClose &&
            <button className={styles["close-btn"]} onClick={() => setHide(false)}>
              <CloseIcon />
            </button>
          }
        </div>
      </div>
    )
  );
};
