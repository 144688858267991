import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import { Router, View } from 'react-navi';
import store from 'store/store';
import "bootstrap/dist/css/bootstrap.min.css";
import "./localization/i18n";
import "./index.scss";
import { routes } from "router";
import reportWebVitals from './reportWebVitals';
import { bootSegment } from "helpers/analyticsHelpers";

bootSegment();
// TODO: Set up router with at least 2 routes 1 for in app and 1 for webite

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router routes={routes}>
        <Suspense fallback={null}>
          <View />
        </Suspense>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
