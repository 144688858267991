import { ClientRenderMode, RenderMode, RenderModeOnSubmitParams } from "clients/types";

export class IFrameRenderMode implements ClientRenderMode {
  public renderMode: RenderMode;
  public appWrapperClass: string;
  public onFormSubmit: ({ authCode, returnScheme }: RenderModeOnSubmitParams) => void;
  public minimizeFormContent: boolean;

  constructor ({
    clientUri,
    isFormStyled,
  }: {
    clientUri: string;
    isFormStyled: boolean;
  }) {
    this.renderMode = 'iframe';
    this.appWrapperClass = `App__iframe${isFormStyled ? ' styled' : ''}`;
    this.minimizeFormContent = false;

    this.onFormSubmit = ({ authCode }) => {
      window.parent.postMessage(
        JSON.stringify({
          type: "login",
          payload: {
            code: authCode,
          },
        }),
        clientUri,
      );
    }
  }
}